/* eslint-disable no-dupe-keys */
export const ENV_DEV_UI = 'development_ui'; // kubernetes development
export const ENV_DEV_K8 = 'development_k8'; // kubernetes development
export const ENV_DEV_K8_BETA = 'development_k8_beta'; // kubernetes development beta
export const ENV_STAGING_K8 = 'staging_k8'; // kubernetes staging
export const ENV_STAGING_BETA_K8 = 'staging_beta_k8'; // kubernetes staging beta
export const ENV_PRODUCTION_R1 = 'production_r1'; // EU Production
export const ENV_PRODUCTION_R2 = 'production_r2'; // US Production
export const ENV_PRODUCTION_BETA_K8 = 'production_us_beta_K8'; // US Production beta k8
export const ENV_PRODUCTION_EU_BETA_K8 = 'production_eu_beta_k8'; // EU Production beta k8

export const DEV_UI_URL = 'localhost';
// K8
export const DEV_K8_URL = 'ws.r1.cortix.ai';
export const DEV_K8_URL_BETA = 'ws.r1.cortix.ai';
export const STAGING_K8_URL = 'ws.r2.cortix.ai';

export const PRODUCTION_EU_URL = 'access.cortix.ai';
export const PRODUCTION_US_URL = 'insights.cortix.ai';

export const PATH_K8_BETA = '/beta/insights';

export const EULA_LINK = 'https://cortix.ai/public/CORTIX%20EULA%20031121.htm';

export const config = {
  env: 'staging',
  development_ui: {
    baseUrl: 'http://localhost:8010/proxy/beta/v5/tile',
    tenant: 'connectcortix.onmicrosoft.com',
    // the policy to use to sign in, can also be a sign up or sign in policy
    signInPolicy: 'B2C_1A_Cortix_SignUpSignIn',
    impersonatePolicy: 'B2C_1A_Cortix_SignUpSignIn_Impr',
    // signInPolicy: "B2C_1_STG_PLATFORM_AADB2C_POLICY",
    // the the B2C application you want to authenticate with
    applicationId: '216cb721-6175-4272-a5c0-4b76c86293ca',
    // where MSAL will store state - localStorage or sessionStorage
    cacheLocation: 'localStorage',
    scopes: ['https://ConnectCortix.onmicrosoft.com/api/cortix_web_api'],
    // redirectUri: "http://localhost:3001/home",
    redirectUriImpersonate: 'https://ws.r1.cortix.ai/beta/insights/impersonate',
    // redirectUri: 'https://ws.r1.cortix.ai/beta/insights',
    redirectUri: 'http://localhost:3000/insights',
    defaultMeasurement: 'unit2',
    forgotPasswordPolicy: 'B2C_1_CortixPasswordResetPolicy',
    // postLogoutRedirectUri: 'https://ws.r1.cortix.ai/beta/insights',
    postLogoutRedirectUri: 'http://localhost:3000/insights',
    domain: 'http://localhost:8010/proxy',
    dsAppLink: 'https://ws.r1.cortix.ai/beta/ds',
    authorityUrl: 'https://connectcortix.b2clogin.com/tfp/',
    userAssociationUrl: 'https://ws.r1.cortix.ai/userassociation/associate',
    beta: true,
    apiVersions: {
      // app restriction
      userdet: '/beta/v1',
      userPreference: '/v1',
      eula: '/v1',
      metaData: '/v1',
      inventoryReport: '/beta/v2',
      getTile: '/beta/v5/tile',
      siteSearch: '/v1',
      paiAPIVersion: '/v6',
      paiMataDataAPIVersion: '/v5',
      syncUrl: '/v1',
      siteService: 'v1',
      assetService: '/v1',
      siteHistory: '/v1',
      liveHistory: '/v1',
      uiTemplate: '/v2',
      siteListReport: '/v1',
      rawData: '/v2',
      userAccountDelete: '/beta/v1',
      tempAndCons: '/v1',
    },
    defaultLanguage: {
      languageCode: 'en',
      language: 'english',
      value: 'en',
      label: 'English',
    },
    cacheEnabled: true,
    view: {
      autoCollapse: false,
      navHistoryMaxDays: 2,
      multiFilter: true,
    },
    // app restriction
    apiTimeout: '300000',
    applicationInsights: false,
    // baseUrl: "https://insights.cortix.ai/v5/tile",
    // tenant: "LoginCortixR2.onmicrosoft.com",
    // // the policy to use to sign in, can also be a sign up or sign in policy
    // signInPolicy: "B2C_1A_Cortix_SignUpSignIn",
    // impersonatePolicy: "B2C_1A_Cortix_SignUpSignIn_Impr",
    // // the the B2C application you want to authenticate with
    // applicationId: "cf1dbd6c-ec0f-40e4-a56e-70e91a7f2feb",
    // // where MSAL will store state - localStorage or sessionStorage
    // cacheLocation: "localStorage",
    // scopes: ["https://LoginCortixR2.onmicrosoft.com/api/cortix_web_api"],
    // redirectUriImpersonate: "https://insights.cortix.ai/beta/insights/impersonate",
    // // redirectUri: "https://insights.cortix.ai/beta/insights",
    // redirectUri: "http://localhost:3000",
    // forgotPasswordPolicy: "B2C_1_PRD_R2_PLATFORM_AADB2C_FORGOT_PASSWORD_POLICY",
    // defaultMeasurement: "unit2",
    // domain: "https://insights.cortix.ai",
    // authorityUrl: "https://logincortixr2.b2clogin.com/tfp/",
    // apiVersions: {
    //   userPreference: '/v1',
    //   eula: '/v1',
    //   metaData: '/v1',
    //   inventoryReport: '/v2',
    //   tile: '/v5/tile',
    // },
    // defaultLanguage: {
    //   languageCode: "en",
    //   language: "english",
    //   value: "en",
    //   label: "English",
    // },
    // cacheEnabled: false,
    // view: {
    //   autoCollapse: false,
    //   navHistoryMaxDays: 2,
    //   multiFilter: true,
    // },
    // baseUrl: 'http://localhost:8010/proxy/beta/v5/tile',
    // tenant: 'connectcortix.onmicrosoft.com',
    // // the policy to use to sign in, can also be a sign up or sign in policy
    // signInPolicy: 'B2C_1A_Cortix_SignUpSignIn_TESTING',
    // impersonatePolicy: 'B2C_1A_Cortix_SignUpSignIn_TESTING_Impr',
    // // the the B2C application you want to authenticate with
    // applicationId: '0682e6f3-7841-499c-8166-1d8e7055e2b5',
    // // where MSAL will store state - localStorage or sessionStorage
    // cacheLocation: 'localStorage',
    // scopes: ['https://connectcortix.onmicrosoft.com/api/cortix_web_api'],
    // redirectUri: 'http://localhost:3000',
    // // redirectUri: "https://ws.r2.cortix.ai/insights",
    // defaultMeasurement: 'unit2',
    // postLogoutRedirectUri: 'http://localhost:3000',
    // forgotPasswordPolicy: 'B2C_1_CortixPasswordResetPolicy',
    // domain: 'http://localhost:8010/proxy',
    // dsAppLink: 'http://localhost:3000/ds',
    // authorityUrl: 'https://connectcortix.b2clogin.com/tfp/',
    // remoteConfigEnv: 'dev',
    // apiVersions: {
    //   userPreference: '/beta/v1',
    //   eula: '/beta/v1',
    //   metaData: '/v1',
    //   inventoryReport: '/beta/v2',
    //   getTile: '/beta/v5/tile',
    //   siteSearch: '/v1',
    //   paiAPIVersion: '/v6',
    //   syncUrl: '/v1',
    //   siteService: 'v1',
    //   assetService: '/v1',
    // },
    // defaultLanguage: {
    //   languageCode: 'en',
    //   language: 'english',
    //   value: 'en',
    //   label: 'English',
    // },
    // cacheEnabled: true,
    // view: {
    //   autoCollapse: false,
    //   navHistoryMaxDays: 2,
    //   multiFilter: true,
    //   showPAIRecoveryDays: false,
    // },
  },
  development_k8: {
    baseUrl: 'https://ws.r1.cortix.ai/v5/tile',
    tenant: 'connectcortix.onmicrosoft.com',
    // the policy to use to sign in, can also be a sign up or sign in policy
    signInPolicy: 'B2C_1A_Cortix_SignUpSignIn',
    impersonatePolicy: 'B2C_1A_Cortix_SignUpSignIn_Impr',
    // signInPolicy: "B2C_1_STG_PLATFORM_AADB2C_POLICY",
    // the the B2C application you want to authenticate with
    applicationId: '216cb721-6175-4272-a5c0-4b76c86293ca',
    // where MSAL will store state - localStorage or sessionStorage
    cacheLocation: 'localStorage',
    scopes: ['https://ConnectCortix.onmicrosoft.com/api/cortix_web_api'],
    // redirectUri: "http://localhost:3001/home",
    redirectUriImpersonate: 'https://ws.r1.cortix.ai/insights/impersonate',
    redirectUri: 'https://ws.r1.cortix.ai/insights',
    defaultMeasurement: 'unit2',
    forgotPasswordPolicy: 'B2C_1_CortixPasswordResetPolicy',
    postLogoutRedirectUri: 'https://ws.r1.cortix.ai/insights',
    domain: 'https://ws.r1.cortix.ai',
    dsAppLink: 'https://ws.r1.cortix.ai/ds',
    authorityUrl: 'https://connectcortix.b2clogin.com/tfp/',
    userAssociationUrl: 'https://ws.r1.cortix.ai/userassociation/associate',
    remoteConfigEnv: 'dev',
    apiVersions: {
      // app restriction
      userdet: '/beta/v1',
      userPreference: '/v1',
      eula: '/v1',
      metaData: '/v1',
      inventoryReport: '/v2',
      getTile: '/v5/tile',
      siteSearch: '/v1',
      paiAPIVersion: '/v6',
      paiMataDataAPIVersion: '/v5',
      syncUrl: '/v1',
      siteService: 'v1',
      assetService: '/v1',
      siteHistory: '/v1',
      liveHistory: '/v1',
      uiTemplate: '/v2',
      siteListReport: '/v1',
      rawData: '/v2',
      userAccountDelete: '/beta/v1',
      tempAndCons: '/v1',
    },
    defaultLanguage: {
      languageCode: 'en',
      language: 'english',
      value: 'en',
      label: 'English',
    },
    cacheEnabled: true,
    view: {
      autoCollapse: false,
      navHistoryMaxDays: 2,
      multiFilter: true,
      showPAIRecoveryDays: false,
    },
    // app restriction
    apiTimeout: '300000',
    applicationInsights: false,
  },
  development_k8_beta: {
    baseUrl: 'https://ws.r1.cortix.ai/beta/v5/tile',
    tenant: 'connectcortix.onmicrosoft.com',
    // the policy to use to sign in, can also be a sign up or sign in policy
    signInPolicy: 'B2C_1A_Cortix_SignUpSignIn',
    impersonatePolicy: 'B2C_1A_Cortix_SignUpSignIn_Impr',
    // signInPolicy: "B2C_1_STG_PLATFORM_AADB2C_POLICY",
    // the the B2C application you want to authenticate with
    applicationId: '216cb721-6175-4272-a5c0-4b76c86293ca',
    // where MSAL will store state - localStorage or sessionStorage
    cacheLocation: 'localStorage',
    scopes: ['https://ConnectCortix.onmicrosoft.com/api/cortix_web_api'],
    // redirectUri: "http://localhost:3001/home",
    redirectUriImpersonate: 'https://ws.r1.cortix.ai/beta/insights/impersonate',
    redirectUri: 'https://ws.r1.cortix.ai/beta/insights',
    defaultMeasurement: 'unit2',
    forgotPasswordPolicy: 'B2C_1_CortixPasswordResetPolicy',
    postLogoutRedirectUri: 'https://ws.r1.cortix.ai/beta/insights',
    domain: 'https://ws.r1.cortix.ai',
    dsAppLink: 'https://ws.r1.cortix.ai/beta/ds',
    authorityUrl: 'https://connectcortix.b2clogin.com/tfp/',
    userAssociationUrl: 'https://ws.r1.cortix.ai/userassociation/associate',
    beta: true,
    remoteConfigEnv: 'dev',
    apiVersions: {
      // app restriction
      userdet: '/beta/v1',
      userPreference: '/v1',
      eula: '/v1',
      metaData: '/v1',
      inventoryReport: '/beta/v2',
      getTile: '/beta/v5/tile',
      siteSearch: '/v1',
      paiAPIVersion: '/v6',
      paiMataDataAPIVersion: '/v5',
      syncUrl: '/v1',
      siteService: 'v1',
      assetService: '/v1',
      siteHistory: '/v1',
      liveHistory: '/v1',
      uiTemplate: '/v2',
      siteListReport: '/v1',
      rawData: '/v2',
      userAccountDelete: '/beta/v1',
      tempAndCons: '/v1',
    },
    defaultLanguage: {
      languageCode: 'en',
      language: 'english',
      value: 'en',
      label: 'English',
    },
    cacheEnabled: true,
    view: {
      autoCollapse: false,
      navHistoryMaxDays: 2,
      multiFilter: true,
      showPAIRecoveryDays: false,
    },
    // app restriction
    apiTimeout: '300000',
    applicationInsights: false,
  },
  staging_k8: {
    baseUrl: 'https://ws.r2.cortix.ai/v5/tile',
    tenant: 'connectcortix.onmicrosoft.com',
    // the policy to use to sign in, can also be a sign up or sign in policy
    signInPolicy: 'B2C_1A_Cortix_SignUpSignIn_TESTING',
    impersonatePolicy: 'B2C_1A_Cortix_SignUpSignIn_TESTING_Impr',
    // the the B2C application you want to authenticate with
    applicationId: '0682e6f3-7841-499c-8166-1d8e7055e2b5',
    // where MSAL will store state - localStorage or sessionStorage
    cacheLocation: 'localStorage',
    scopes: ['https://connectcortix.onmicrosoft.com/api/cortix_web_api'],
    // redirectUri: "http://localhost:3001/home",
    redirectUriImpersonate: 'https://ws.r2.cortix.ai/insights/impersonate',
    redirectUri: 'https://ws.r2.cortix.ai/insights',
    defaultMeasurement: 'unit2',
    postLogoutRedirectUri: 'https://ws.r2.cortix.ai/insights',
    forgotPasswordPolicy: 'B2C_1_CortixPasswordResetPolicy',
    domain: 'https://ws.r2.cortix.ai',
    dsAppLink: 'https://ws.r2.cortix.ai/ds',
    authorityUrl: 'https://connectcortix.b2clogin.com/tfp/',
    userAssociationUrl: 'https://ws.r2.cortix.ai/userassociation/associate',
    remoteConfigEnv: 'dev',
    apiVersions: {
      // app restriction
      userdet: '/beta/v1',
      userPreference: '/v1',
      eula: '/v1',
      metaData: '/v1',
      inventoryReport: '/v2',
      getTile: '/v5/tile',
      siteSearch: '/v1',
      paiAPIVersion: '/v6',
      paiMataDataAPIVersion: '/v5',
      syncUrl: '/v1',
      siteService: 'v1',
      assetService: '/v1',
      siteHistory: '/v1',
      liveHistory: '/v1',
      uiTemplate: '/v2',
      siteListReport: '/v1',
      rawData: '/v2',
      userAccountDelete: '/beta/v1',
      tempAndCons: '/v1',
    },
    defaultLanguage: {
      languageCode: 'en',
      language: 'english',
      value: 'en',
      label: 'English',
    },
    cacheEnabled: true,
    view: {
      autoCollapse: false,
      navHistoryMaxDays: 2,
      multiFilter: true,
      showPAIRecoveryDays: false,
    },
    // app restriction
    apiTimeout: '300000',
    applicationInsights: false,
  },
  staging_beta_k8: {
    baseUrl: 'https://ws.r2.cortix.ai/beta/v5/tile',
    tenant: 'connectcortix.onmicrosoft.com',
    // the policy to use to sign in, can also be a sign up or sign in policy
    signInPolicy: 'B2C_1A_Cortix_SignUpSignIn_TESTING',
    impersonatePolicy: 'B2C_1A_Cortix_SignUpSignIn_TESTING_Impr',
    // the the B2C application you want to authenticate with
    applicationId: '0682e6f3-7841-499c-8166-1d8e7055e2b5',
    // where MSAL will store state - localStorage or sessionStorage
    cacheLocation: 'localStorage',
    scopes: ['https://connectcortix.onmicrosoft.com/api/cortix_web_api'],
    // redirectUri: "http://localhost:3001/home",
    redirectUri: 'https://ws.r2.cortix.ai/beta/insights',
    redirectUriImpersonate: 'https://ws.r2.cortix.ai/beta/insights/impersonate',
    defaultMeasurement: 'unit2',
    postLogoutRedirectUri: 'https://ws.r2.cortix.ai/beta/insights',
    forgotPasswordPolicy: 'B2C_1_CortixPasswordResetPolicy',
    domain: 'https://ws.r2.cortix.ai',
    dsAppLink: 'https://ws.r2.cortix.ai/beta/ds',
    authorityUrl: 'https://connectcortix.b2clogin.com/tfp/',
    userAssociationUrl: 'https://ws.r2.cortix.ai/userassociation/associate',
    beta: true,
    remoteConfigEnv: 'dev',
    apiVersions: {
      // app restriction
      userdet: '/beta/v1',
      userPreference: '/beta/v1',
      eula: '/beta/v1',
      metaData: '/v1',
      inventoryReport: '/beta/v2',
      getTile: '/beta/v5/tile',
      siteSearch: '/v1',
      paiAPIVersion: '/v6',
      paiMataDataAPIVersion: '/v5',
      syncUrl: '/v1',
      siteService: 'v1',
      assetService: '/v1',
      siteHistory: '/v1',
      liveHistory: '/v1',
      uiTemplate: '/v2',
      siteListReport: '/v1',
      rawData: '/v2',
      userAccountDelete: '/beta/v1',
      tempAndCons: '/v1',
    },
    defaultLanguage: {
      languageCode: 'en',
      language: 'english',
      value: 'en',
      label: 'English',
    },
    cacheEnabled: true,
    view: {
      autoCollapse: false,
      navHistoryMaxDays: 2,
      multiFilter: true,
      showPAIRecoveryDays: false,
    },
    // app restriction
    apiTimeout: '300000',
    applicationInsights: false,
  },
  // europe
  production_r1: {
    baseUrl: 'https://access.cortix.ai/v5/tile',
    tenant: 'LoginCortixR1.onmicrosoft.com',
    // the policy to use to sign in, can also be a sign up or sign in policy
    signInPolicy: 'B2C_1A_Cortix_SignUpSignIn',
    impersonatePolicy: 'B2C_1A_Cortix_SignUpSignIn_Impr',
    // the the B2C application you want to authenticate with
    applicationId: 'd69a2536-db01-49a1-bfae-d80da0af707e',
    // where MSAL will store state - localStorage or sessionStorage
    cacheLocation: 'localStorage',
    scopes: ['https://LoginCortixR1.onmicrosoft.com/api/cortix_web_api'],
    forgotPasswordPolicy: 'B2C_1_PRD_R1_PLATFORM_AADB2C_FORGOT_PASSWORD_POLICY',
    redirectUriImpersonate: 'https://access.cortix.ai/insights/impersonate',
    redirectUri: 'https://access.cortix.ai/insights',
    defaultMeasurement: 'unit1',
    postLogoutRedirectUri: 'https://access.cortix.ai/insights',
    domain: 'https://access.cortix.ai',
    dsAppLink: 'https://access.cortix.ai/ds',
    authorityUrl: 'https://logincortixr1.b2clogin.com/tfp/',
    userAssociationUrl: 'https://access.cortix.ai/userassociation/associate',
    remoteConfigEnv: 'prod',
    apiVersions: {
      // app restriction
      userdet: '/v1',
      userPreference: '/v1',
      eula: '/v1',
      metaData: '/v1',
      inventoryReport: '/v2',
      getTile: '/v5/tile',
      siteSearch: '/v1',
      paiAPIVersion: '/v6',
      paiMataDataAPIVersion: '/v5',
      syncUrl: '/v1',
      siteService: 'v1',
      assetService: '/v1',
      siteHistory: '/v1',
      liveHistory: '/v1',
      uiTemplate: '/v2',
      siteListReport: '/v1',
      rawData: '/v2',
      userAccountDelete: '/v1',
      tempAndCons: '/v1',
    },
    defaultLanguage: {
      languageCode: 'en',
      language: 'english',
      value: 'en',
      label: 'English',
    },
    cacheEnabled: true,
    view: {
      autoCollapse: false,
      navHistoryMaxDays: 2,
      multiFilter: true,
      showPAIRecoveryDays: false,
    },
    // app restriction
    apiTimeout: '300000',
    applicationInsights: true,
  },
  // us
  production_r2: {
    baseUrl: 'https://insights.cortix.ai/v5/tile',
    tenant: 'LoginCortixR2.onmicrosoft.com',
    // the policy to use to sign in, can also be a sign up or sign in policy
    signInPolicy: 'B2C_1A_Cortix_SignUpSignIn',
    impersonatePolicy: 'B2C_1A_Cortix_SignUpSignIn_Impr',
    // the the B2C application you want to authenticate with
    applicationId: 'cf1dbd6c-ec0f-40e4-a56e-70e91a7f2feb',
    // where MSAL will store state - localStorage or sessionStorage
    cacheLocation: 'localStorage',
    scopes: ['https://LoginCortixR2.onmicrosoft.com/api/cortix_web_api'],
    redirectUriImpersonate: 'https://insights.cortix.ai/insights/impersonate',
    redirectUri: 'https://insights.cortix.ai/insights',
    forgotPasswordPolicy: 'B2C_1_PRD_R2_PLATFORM_AADB2C_FORGOT_PASSWORD_POLICY',
    defaultMeasurement: 'unit2',
    postLogoutRedirectUri: 'https://insights.cortix.ai/insights',
    domain: 'https://insights.cortix.ai',
    dsAppLink: 'https://insights.cortix.ai/ds',
    authorityUrl: 'https://logincortixr2.b2clogin.com/tfp/',
    userAssociationUrl: 'https://insights.cortix.ai/userassociation/associate',
    remoteConfigEnv: 'prod',
    apiVersions: {
      // app restriction
      userdet: '/v1',
      userPreference: '/v1',
      eula: '/v1',
      metaData: '/v1',
      inventoryReport: '/v2',
      paiAPIVersion: '/v6',
      paiMataDataAPIVersion: '/v5',
      syncUrl: '/v1',
      getTile: '/v5/tile',
      siteSearch: '/v1',
      siteService: 'v1',
      assetService: '/v1',
      siteHistory: '/v1',
      liveHistory: '/v1',
      uiTemplate: '/v2',
      siteListReport: '/v1',
      rawData: '/v2',
      userAccountDelete: '/v1',
      tempAndCons: '/v1',
    },
    defaultLanguage: {
      languageCode: 'en',
      language: 'english',
      value: 'en',
      label: 'English',
    },
    cacheEnabled: true,
    view: {
      autoCollapse: false,
      navHistoryMaxDays: 2,
      multiFilter: true,
      showPAIRecoveryDays: false,
    },
    // app restriction
    apiTimeout: '300000',
    applicationInsights: true,
  },
  // us beta
  production_us_beta_K8: {
    baseUrl: 'https://insights.cortix.ai/v5/tile',
    tenant: 'LoginCortixR2.onmicrosoft.com',
    // the policy to use to sign in, can also be a sign up or sign in policy
    signInPolicy: 'B2C_1A_Cortix_SignUpSignIn',
    impersonatePolicy: 'B2C_1A_Cortix_SignUpSignIn_Impr',
    // the the B2C application you want to authenticate with
    applicationId: 'cf1dbd6c-ec0f-40e4-a56e-70e91a7f2feb',
    // where MSAL will store state - localStorage or sessionStorage
    cacheLocation: 'localStorage',
    scopes: ['https://LoginCortixR2.onmicrosoft.com/api/cortix_web_api'],
    redirectUriImpersonate:
      'https://insights.cortix.ai/beta/insights/impersonate',
    redirectUri: 'https://insights.cortix.ai/beta/insights',
    forgotPasswordPolicy: 'B2C_1_PRD_R2_PLATFORM_AADB2C_FORGOT_PASSWORD_POLICY',
    defaultMeasurement: 'unit2',
    postLogoutRedirectUri: 'https://insights.cortix.ai/beta/insights',
    domain: 'https://insights.cortix.ai',
    dsAppLink: 'https://insights.cortix.ai/beta/ds',
    authorityUrl: 'https://logincortixr2.b2clogin.com/tfp/',
    userAssociationUrl:
      'https://insights.cortix.ai/beta/userassociation/associate',
    beta: true,
    remoteConfigEnv: 'prod',
    apiVersions: {
      // app restriction
      userdet: '/beta/v1',
      userPreference: '/beta/v1',
      eula: '/beta/v1',
      metaData: '/v1',
      inventoryReport: '/beta/v2',
      getTile: '/v5/tile',
      siteSearch: '/v1',
      paiAPIVersion: '/v6',
      paiMataDataAPIVersion: '/v5',
      syncUrl: '/v1',
      siteService: 'v1',
      assetService: '/v1',
      siteHistory: '/v1',
      liveHistory: '/v1',
      uiTemplate: '/v2',
      siteListReport: '/v1',
      rawData: '/v2',
      userAccountDelete: '/v1',
      tempAndCons: '/v1',
    },
    defaultLanguage: {
      languageCode: 'en',
      language: 'english',
      value: 'en',
      label: 'English',
    },
    cacheEnabled: true,
    view: {
      autoCollapse: false,
      navHistoryMaxDays: 2,
      multiFilter: true,
      showPAIRecoveryDays: false,
    },
    // app restriction
    apiTimeout: '300000',
    applicationInsights: true,
  },
  // europe beta
  production_eu_beta_k8: {
    baseUrl: 'https://access.cortix.ai/v5/tile',
    tenant: 'LoginCortixR1.onmicrosoft.com',
    // the policy to use to sign in, can also be a sign up or sign in policy
    signInPolicy: 'B2C_1A_Cortix_SignUpSignIn',
    impersonatePolicy: 'B2C_1A_Cortix_SignUpSignIn_Impr',
    // the the B2C application you want to authenticate with
    applicationId: 'd69a2536-db01-49a1-bfae-d80da0af707e',
    // where MSAL will store state - localStorage or sessionStorage
    cacheLocation: 'localStorage',
    scopes: ['https://LoginCortixR1.onmicrosoft.com/api/cortix_web_api'],
    forgotPasswordPolicy: 'B2C_1_PRD_R1_PLATFORM_AADB2C_FORGOT_PASSWORD_POLICY',
    redirectUriImpersonate:
      'https://access.cortix.ai/beta/insights/impersonate',
    redirectUri: 'https://access.cortix.ai/beta/insights',
    defaultMeasurement: 'unit1',
    postLogoutRedirectUri: 'https://access.cortix.ai/beta/insights',
    domain: 'https://access.cortix.ai',
    dsAppLink: 'https://access.cortix.ai/beta/ds',
    authorityUrl: 'https://logincortixr1.b2clogin.com/tfp/',
    userAssociationUrl: 'https://access.cortix.ai/userassociation/associate',
    beta: true,
    remoteConfigEnv: 'prod',
    apiVersions: {
      // app restriction
      userdet: '/v1',
      userPreference: '/beta/v1',
      eula: '/beta/v1',
      metaData: '/v1',
      inventoryReport: '/beta/v2',
      getTile: '/v5/tile',
      siteSearch: '/v1',
      paiAPIVersion: '/v6',
      paiMataDataAPIVersion: '/v5',
      syncUrl: '/v1',
      siteService: 'v1',
      assetService: '/v1',
      siteHistory: '/v1',
      liveHistory: '/v1',
      uiTemplate: '/v2',
      siteListReport: '/v1',
      rawData: '/v2',
      userAccountDelete: '/v1',
      tempAndCons: '/v1',
    },
    defaultLanguage: {
      languageCode: 'en',
      language: 'english',
      value: 'en',
      label: 'English',
    },
    cacheEnabled: true,
    view: {
      autoCollapse: false,
      navHistoryMaxDays: 2,
      multiFilter: true,
      showPAIRecoveryDays: false,
    },
    // app restriction
    apiTimeout: '300000',
    applicationInsights: true,
  },
};
